import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// ---------------------------------------------------------

import RealtorCard from '@components/realtor-card';
import ContactForm from '@components/forms';
import useWindowSize from '@lib/window-size';

// ---------------------------------------------------------

import { container, subheader, two_agents, realtor_card } from './styles.module.scss';

// ---------------------------------------------------------

const RequestATour = (props) => {
  const {
    primaryContactEmail,
    primaryContactPhotoUrl,
    primaryContactLanguages,
    primaryContactName,
    primaryContactPhone,
    primaryContactProfessionalTitle,
    primaryContactWebsiteUrl,
    primaryContactTeamName,
    secondaryContactEmail,
    secondaryContactPhotoUrl,
    secondaryContactLanguages,
    secondaryContactName,
    secondaryContactPhone,
    secondaryContactProfessionalTitle,
    secondaryContactWebsiteUrl,
    defaultFormValue,
    endpoint,
    contactText,
    hasTour,
    onListingPage,
    hasSecondaryAgent,
    listingId,
    userInfo,
    sticky,
    communityName,
    communityState,
    blogPath,
    blogTitle,
    agentId,
    setModalIsOpen,
    title,
    category
  } = props;
  const ref = useRef();
  const [referenceHeight, setReferenceHeight] = useState(0);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'chat-visible' });

    setReferenceHeight(ref.current?.clientHeight || 0);
  }, []);

  // ---------------------------------------------------------

  return (
    <div
      className={container}
      ref={ref}
      id="isSibcyClineListing"
      style={{
        position: referenceHeight + 50 < useWindowSize().height && sticky ? 'sticky' : 'inherit',
        top: referenceHeight + 50 < useWindowSize().height && sticky ? '20px' : '0px'
      }}
    >
      {hasSecondaryAgent ? (
        <div className={two_agents}>
          <RealtorCard
            theme="horizontalSmall"
            onListingPage={onListingPage}
            email={primaryContactEmail}
            imageSrc={primaryContactPhotoUrl}
            languages={primaryContactLanguages}
            name={primaryContactName}
            phone={primaryContactPhone}
            title={primaryContactProfessionalTitle}
            website={primaryContactWebsiteUrl}
            hasTour={hasTour}
            setModalIsOpen={setModalIsOpen}
          />
          <RealtorCard
            theme="horizontalSmall"
            onListingPage={onListingPage}
            email={secondaryContactEmail}
            imageSrc={secondaryContactPhotoUrl}
            name={secondaryContactName}
            phone={secondaryContactPhone}
            website={secondaryContactWebsiteUrl}
            languages={secondaryContactLanguages}
            title={secondaryContactProfessionalTitle}
            hasTour={hasTour}
            setModalIsOpen={setModalIsOpen}
          />
        </div>
      ) : (
        <div className={realtor_card}>
          <RealtorCard
            theme="primary"
            onListingPage={onListingPage}
            email={primaryContactEmail}
            imageSrc={primaryContactPhotoUrl}
            languages={primaryContactLanguages}
            name={primaryContactName}
            phone={primaryContactPhone}
            title={primaryContactProfessionalTitle}
            website={primaryContactWebsiteUrl}
            teamName={primaryContactTeamName}
            hasTour={hasTour}
            setModalIsOpen={setModalIsOpen}
          />
        </div>
      )}
      <span className={subheader}>
        {contactText ? (
          <>{contactText}</>
        ) : (
          <>Contact {hasSecondaryAgent ? `us` : primaryContactName} to learn&nbsp;more</>
        )}
        {hasTour && onListingPage ? (
          <>
            &nbsp;or&nbsp;
            <button onClick={() => setModalIsOpen(true)}> request a tour!</button>
          </>
        ) : (
          '!'
        )}
      </span>
      <ContactForm
        category={category}
        title={title}
        theme="no-background"
        linkLabel="Send Message"
        endpoint={endpoint}
        endpointId={listingId}
        defaultFormValue={defaultFormValue}
        userInfo={userInfo}
        communityName={communityName}
        communityState={communityState}
        agentId={agentId}
        blogPath={blogPath}
        blogTitle={blogTitle}
      ></ContactForm>
    </div>
  );
};

RequestATour.propTypes = {
  /**
   * Specifies the agent ID
   */
  agentId: PropTypes.string,

  /**
   * Specified the blog path
   */
  blogPath: PropTypes.string,

  /**
   * Specified the blog title
   */
  blogTitle: PropTypes.string,

  /**
   * Specifies whether categorized as a builder or development
   */
  category: PropTypes.string,

  /**
   * Specifies the community name
   */
  communityName: PropTypes.string,

  /**
   * Specifies the community state
   */
  communityState: PropTypes.string,

  /**
   * The contact text for the card
   */
  contactText: PropTypes.string,

  /**
   * Specifies the default value for the text area
   */
  defaultFormValue: PropTypes.string,

  /**
   * Specifies the endpoint for the form
   */
  endpoint: PropTypes.string,

  /**
   * Determines if there is a secondary realtor. If true, two Realtor Cards will display.
   */
  hasSecondaryAgent: PropTypes.bool,

  /**
   * Specifies whether the listing is available for touring
   */
  hasTour: PropTypes.bool,

  /**
   * The listing ID
   */
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Specifies whether the card is on the Listing Detail Page
   */
  onListingPage: PropTypes.bool,

  /**
   * The primary realtor's email address.
   */
  primaryContactEmail: PropTypes.string,

  /**
   * The primary realtor's languages.
   */
  primaryContactLanguages: PropTypes.string,

  /**
   * The primary realtor's name.
   */
  primaryContactName: PropTypes.string,

  /**
   * The primary realtor's phone number.
   */
  primaryContactPhone: PropTypes.string,

  /**
   * The primary realtor's photo url.
   */
  primaryContactPhotoUrl: PropTypes.string,

  /**
   * The primary realtor's title.
   */
  primaryContactProfessionalTitle: PropTypes.string,

  /**
   * The primary realtor's team name.
   */
  primaryContactTeamName: PropTypes.string,

  /**
   * The primary realtor's webiste url.
   */
  primaryContactWebsiteUrl: PropTypes.string,

  /**
   * The secondary realtor's email address.
   */
  secondaryContactEmail: PropTypes.string,

  /**
   * The secondary realtor's languages.
   */
  secondaryContactLanguages: PropTypes.string,

  /**
   * The secondary realtor's name.
   */
  secondaryContactName: PropTypes.string,

  /**
   * The secondary realtor's phone number.
   */
  secondaryContactPhone: PropTypes.string,

  /**
   * The secondary realtor's photo url.
   */
  secondaryContactPhotoUrl: PropTypes.string,

  /**
   * The secondary realtor's title.
   */
  secondaryContactProfessionalTitle: PropTypes.string,

  /**
   * The secondary realtor's website url.
   */
  secondaryContactWebsiteUrl: PropTypes.string,

  /**
   * Sets the Schedule A Tour modal on the LDP to open
   */
  setModalIsOpen: PropTypes.func,

  /**
   * Specifies whether the card is sticky.
   */
  sticky: PropTypes.bool,

  /**
   * Specifies the builder/development title
   */
  title: PropTypes.string,
  /**
   * Specifies the logged in user's information
   */
  userInfo: PropTypes.object
};

RequestATour.defaultProps = {
  endpoint: 'property information request'
};

export default RequestATour;
