const sharedProps = {
  email: 'hello@example.com',
  imageSrc: 'https://i.stack.imgur.com/HQwHI.jpg',
  languages: 'Spanish',
  name: 'Jason Kenson',
  phone: '555-555-5555',
  title: 'Realtor',
  website: 'https://www.sibcycline.com'
};

export default {
  horizontalLarge: {
    ...sharedProps,
    theme: 'horizontalLarge'
  },
  horizontalSmall: {
    ...sharedProps,
    theme: 'horizontalSmall'
  },
  officeDetail: {
    ...sharedProps,
    theme: 'officeDetail'
  },
  primary: {
    ...sharedProps,
    theme: 'primary'
  },
  props: {
    ...sharedProps
  }
};
