export default {
  props: {
    hasSecondaryAgent: false,
    primaryContactEmail: 'hello@example.com',
    primaryContactLanguages: 'Spanish',
    primaryContactName: 'Jason Kenson',
    primaryContactPhone: '555-555-5555',
    primaryContactPhotoUrl: 'https://i.stack.imgur.com/HQwHI.jpg',
    primaryContactProfessionalTitle: 'Realtor',
    primaryContactWebsiteUrl: 'https://www.sibcycline.com',
    secondaryContactEmail: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactPhotoUrl: '',
    secondaryContactWebsiteUrl: '',
    tourUrl: 'https://www.sibcycline.com'
  }
};
