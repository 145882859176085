import { useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';
import Image from '@components/image';

// ---------------------------------------------------------

import * as styles from './styles.module.scss';

const themeOptions = {
  horizontalLarge: styles.realtor_card_horizontal_large,
  horizontalSmall: styles.realtor_card_horizontal_small,
  officeDetail: styles.realtor_card_office_detail,
  primary: styles.realtor_card_primary
};

// ---------------------------------------------------------

const RealtorCard = ({
  theme,
  imageSrc,
  name,
  teamName,
  title,
  languages,
  hasTour,
  designations,
  email,
  phone,
  onListingPage,
  website,
  setModalIsOpen,
  isOhioLicensed,
  isKentuckyLicensed,
  isIndianaLicensed
}) => {
  const router = useRouter();

  const containerClasses = classNames(styles.container, {
    [styles.full_width]: onListingPage
  });

  const classes = classNames({
    [styles.on_listing_page]: onListingPage,
    [themeOptions[theme]]: themeOptions[theme]
  });

  useEffect(() => {
    if (hasTour && router?.isReady) {
      setModalIsOpen(!!router?.query?.tourModal);
    }
  }, [router]);

  const licenseString = () => {
    const licenses = [];
    if (isOhioLicensed) licenses.push('OH');
    if (isKentuckyLicensed) licenses.push('KY');
    if (isIndianaLicensed) licenses.push('IN');
    return licenses.length ? `Licensed in ${licenses.join(', ')}` : '';
  };

  return (
    <div className={containerClasses}>
      <div className={classes}>
        <div className={styles.bg_container}>
          <div className={styles.bg_overlay}></div>
        </div>
        <Link url={website} className={styles.realtor_img}>
          <Image
            image={{
              alt: `Headshot of ${name || teamName}`,
              height: 125,
              src: imageSrc ? imageSrc : '/images/avatar_placeholder.jpeg',
              width: 165
            }}
            layout="responsive"
          />
        </Link>
        <div className={styles.inner_content}>
          <Link url={website}>
            <h2 className={styles.realtor_name}>{name}</h2>
          </Link>
          {teamName && <div className={styles.realtor_team}>{teamName}</div>}
          <h3 className={styles.realtor_subtitle}>{title && title}</h3>
          {phone && onListingPage && (
            <div className={styles.phone_number}>
              <Link url={`tel:${phone}`}>{phone}</Link>
            </div>
          )}
          {designations && <div className={styles.realtor_languages}>{designations}</div>}
          {languages && <div className={styles.realtor_languages}>Speaks {languages}</div>}
          {licenseString() && <div className={styles.realtor_licenses}>{licenseString()}</div>}
        </div>
        <div className={styles.contact_content}>
          {onListingPage ? (
            <>
              {phone && (
                <span className={styles.contact_link}>
                  <Link url={`tel:${phone}`}>
                    <Icon name="phone" type="actions" />
                    Call
                  </Link>
                </span>
              )}
              {phone && (
                <span className={styles.contact_link}>
                  <Link url={`sms:${phone}`}>
                    <Icon name="text" type="actions" />
                    Text
                  </Link>
                </span>
              )}
              {hasTour ? (
                <span className={styles.contact_link}>
                  <button onClick={() => setModalIsOpen(true)}>
                    <Icon type="general" name="clock" />
                    Tour
                  </button>
                </span>
              ) : (
                website && (
                  <span className={styles.contact_link}>
                    <Link url={website}>
                      <Icon name="website" type="actions" />
                      Website
                    </Link>
                  </span>
                )
              )}
            </>
          ) : (
            <>
              {email && (
                <span className={styles.contact_link}>
                  <Link url={`mailto:${email}`}>
                    <Icon name="email" type="actions" />
                    Email
                  </Link>
                </span>
              )}
              {phone && (
                <span className={styles.contact_link}>
                  <Link url={`tel:${phone}`}>
                    <Icon name="phone" type="actions" />
                    Call
                  </Link>
                </span>
              )}
              {website && (
                <span className={styles.contact_link}>
                  <Link url={website}>
                    <Icon name="website" type="actions" />
                    Website
                  </Link>
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

RealtorCard.propTypes = {
  /**
   * Specifies list of designations this agent has earned
   */
  designations: PropTypes.string,

  /**
   * The realtor's email address.
   */
  email: PropTypes.string,

  /**
   * Specifies if the listing has a the request a tour
   */
  hasTour: PropTypes.bool,

  /**
   * The image for the realtor.
   */
  imageSrc: PropTypes.string,

  /**
   * Languages spoken by the realtor. Won't display if the field is blank.
   */
  languages: PropTypes.string,

  /**
   * The realtor's full name.
   */
  name: PropTypes.string,

  /**
   * Specifies if component renders on listing page (changes styles)
   */
  onListingPage: PropTypes.bool,

  /**
   * The realtor's phone number.
   */
  phone: PropTypes.string,

  /**
   * Function to open the request a tour modal
   */
  setModalIsOpen: PropTypes.func,

  /**
   * The realtor's team name.
   */
  teamName: PropTypes.string,

  /**
   * Controls the layout of the realtor card.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * The realtor's title.
   */
  title: PropTypes.string,

  /**
   * The url to schedule a tour for the given property.
   */
  tourUrl: PropTypes.string,

  /**
   * The realtor's website.
   */
  website: PropTypes.string,

  /**
   * Whether the realtor is licensed in Ohio
   */
  isOhioLicensed: PropTypes.bool,

  /**
   * Whether the realtor is licensed in Kentucky
   */
  isKentuckyLicensed: PropTypes.bool,

  /**
   * Whether the realtor is licensed in Indiana
   */
  isIndianaLicensed: PropTypes.bool
};

RealtorCard.defaultProps = {
  theme: 'primary'
};

export default RealtorCard;
